<template>
  <div class="GlobalTable">
    <div class="button_top">
      <div class="faultBtn">
        <div :class="auditStatus === 1 ? 'active' : 'none'" @click="btnClick(1)">
          待审核({{ applyNumber.waitNum || 0 }})
        </div>
        <div :class="auditStatus === 2 ? 'active' : 'none'" @click="btnClick(2)">
          放款中({{ applyNumber.ingNum || 0 }})
        </div>
        <div :class="auditStatus === 4 ? 'active' : 'none'" @click="btnClick(4)">
          放款成功({{ applyNumber.successNum || 0 }})
        </div>
        <div :class="auditStatus === 5 ? 'active' : 'none'" @click="btnClick(5)">
          放款失败({{ applyNumber.failNum || 0 }})
        </div>
        <div :class="auditStatus === 3 ? 'active' : 'none'" @click="btnClick(3)">
          已驳回({{ applyNumber.rejectNum || 0 }})
        </div>
      </div>
      <div>
        <el-button size="small" icon="el-icon-plus" type="primary" @click="handBtn('sub')">添加打款</el-button>
      </div>
    </div>
    <GlobalTable ref="GlobalTable" v-loading="loading" :columns="tableColumns" :data="this.seachDataList"
      :currentPage="page.pageNum" :total="page.total" @handleCurrentChange="handleCurrentChange">
      <el-table-column label="打款人" width="130rpx" slot="merchantAddress" align="center" v-if="auditStatus !== 3">
        <template slot-scope="{ row }">
          <div class="Remarks">
            {{ row.payAdminName || "--" }}{{ row.payAdminAccount }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="审核人" width="130rpx" slot="merchantAddress" align="center" v-if="auditStatus === 3">
        <template slot-scope="{ row }">
          <div class="Remarks">
            {{ row.auditAdminName || "--" }}{{ row.auditAdminAccount }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="打款原因" width="130rpx" slot="payNote" align="center">
        <template slot-scope="{ row }">
          <el-tooltip v-if="row.payNote" class="item" effect="dark" :content="row.payNote" placement="top">
            <div class="Remarks">
              {{ row.payNote }}
            </div>
          </el-tooltip>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="凭证" slot="voucher" align="center">
        <template slot-scope="{ row }">
          <img v-if="row.payVoucherImg" @click="seeVoucher(row.payVoucherImg)" :src="row.payVoucherImg[0]" alt="" />
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="商户类型" slot="businessType" align="center">
        <template slot-scope="{ row }">
          <span>{{
            row.businessType == "01"
            ? "门店商"
            : row.businessType == "02"
              ? "回收商"
              : "其他"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="收款商户" slot="merchantName" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.businessType == '01'">{{ row.companyName }}</span>
          <span v-if="row.businessType == '02'">{{ row.merchantName }}</span>
          <span v-else>{{ row.otherName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="收款账户类型" slot="arrivalMethod" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.arrivalMethod == "01" ? "支付宝" : "银行卡" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="银行卡类型" slot="publicType" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.publicType">{{
            row.publicType == 1 ? "对公账户" : "个人私卡"
          }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="收款人" slot="chamberlain" align="center">
        <template slot-scope="{ row }">
          <span>{{
            row.arrivalMethod == "01" ? row.alipayUserName : row.bankCardholder
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="收款卡号" slot="bankCardCode" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.bankCardCode || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="收款银行" slot="bankName" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.bankName || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="分支行" slot="bankOpening" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.bankOpening || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="支付宝账号" slot="alipayAccount" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.alipayAccount || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="打款金额" slot="amount" align="center">
        <template slot-scope="{ row }">
          <span>￥{{ row.amount || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="手续费" slot="cashOutFee" align="center">
        <template slot-scope="{ row }">
          <span>￥{{ row.cashOutFee || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="到账时间" slot="arrivalMoment" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.arrivalMethod == '02'">{{
            row.arrivalMoment == "01" ? "实时到账" : "次日到账"
          }}</span>
          <span v-else>实时到账</span>
        </template>
      </el-table-column>
      <el-table-column label="打款失败原因" width="130rpx" slot="failnote" align="center" v-if="auditStatus === 5">
        <template slot-scope="{ row }">
          <el-tooltip v-if="row.errorMsg" class="item" effect="dark" :content="row.errorMsg" placement="top">
            <div class="Remarks">
              {{ row.errorMsg }}
            </div>
          </el-tooltip>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="驳回原因" width="130rpx" slot="failnote" align="center" v-if="auditStatus === 3">
        <template slot-scope="{ row }">
          <el-tooltip v-if="row.rejectCause" class="item" effect="dark" :content="row.rejectCause" placement="top">
            <div class="Remarks">
              {{ row.rejectCause }}
            </div>
          </el-tooltip>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="备注说明" width="130rpx" slot="note" align="center">
        <template slot-scope="{ row }">
          <el-tooltip v-if="row.note" class="item" effect="dark" :content="row.note" placement="top">
            <div class="Remarks">
              {{ row.note }}
            </div>
          </el-tooltip>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column width="240" label="操作" fixed="right" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="success" round @click="handleBtn(row, 'remark')">{{ row.note ? "修改备注" : "添加备注"
          }}</el-button>
          <el-button v-if="auditStatus === 1" size="mini" type="primary" round @click="auditTrunon(row)">审核</el-button>
          <el-button size="mini" v-if="auditStatus === 2" type="warning" round
            @click="handleBtn(row, 'supplement')">补单查询</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 备注弹出框 -->
    <el-dialog class="remarks_dialog" :title="RemarksTitle" :visible.sync="RemarksShow" :close-on-click-modal="false"
      width="410px" @closed="remarksClosed">
      <div class="remarks-tip"><span>对外收货打款</span></div>
      <div style="margin-bottom: 10px">备注内容</div>
      <el-input maxlength="200" type="textarea" :rows="4" placeholder="请输入备注内容,最多200字" v-model="masterNote"
        show-word-limit>
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="remarksClosed">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="remarksSure">确定</el-button>
      </span>
    </el-dialog>
    <!-- 审核弹出框 -->
    <el-dialog class="remarks_dialog" title="审核操作" :visible.sync="auditShow" :close-on-click-modal="false" width="600px"
      @closed="auditClosed">
      <div class="call_accounts_bill">
        <h3 class="lv_fc">申请信息</h3>
        <div class="call_accounts_info">
          <div class="call_accounts_result">
            <p>
              收款商户：
              <span v-if="callAccountsProps.businessType == '01'">{{
                callAccountsProps.companyName
              }}</span>
              <span v-if="callAccountsProps.businessType == '02'">{{
                callAccountsProps.merchantName
              }}</span>
              <span v-else>{{ callAccountsProps.otherName }}</span>
            </p>
            <p>
              收款人：{{
                callAccountsProps.arrivalMethod == "01"
                ? callAccountsProps.alipayUserName
                : callAccountsProps.bankCardholder
              }}
            </p>
          </div>
          <div style="margin-top: 20px;display: flex;">
            <p>打款金额：{{ callAccountsProps.amount || 0 }}元</p>
            <p style="margin-left: 80px;color: red;">
              {{
                changeNumMoneyToChinese(
                  (callAccountsProps.amount + "").match(/^\d*(\.?\d{0,2})/g)[0]
                )
              }}
            </p>
          </div>
          <div class="call_accounts_result">
            <p>
              申请人：{{
                callAccountsProps.createAdminName +
                "-" +
                callAccountsProps.createAdminAccount
              }}
            </p>
            <p>申请时间：{{ callAccountsProps.createTime }}</p>
          </div>
        </div>
        <h3 class="lv_fc">核账操作</h3>
        <div class="call_accounts_check">
          <el-radio-group v-model="callAccountsProps.state">
            <el-radio label="2">审核通过</el-radio>
            <el-radio label="3">审核驳回</el-radio>
          </el-radio-group>
        </div>
        <el-input type="textarea" :rows="4"
          :placeholder="callAccountsProps.state == '2' ? '请输入审核备注（非必填）' : '请输入驳回原因（必填，驳回原因将反馈给申请人）'"
          v-model="callAccountsProps.auditNote" maxlength="200" show-word-limit />
      </div>
      <div class="command" style="margin-top: 20px;">
        <div style="width: 88px;">动态口令：</div>
        <el-input v-model="callAccountsProps.command" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="auditClosed">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="auditksSure">确定</el-button>
      </span>
    </el-dialog>
    <!-- 补单查询弹出框 -->
    <el-dialog class="repair_dialog" :title="repairTitle" :visible.sync="repairShow" :close-on-click-modal="false"
      width="380px" @closed="repairClosed">
      <div class="repair-conent" v-loading="repairLoading" :element-loading-text="loadingText">
        <img v-if="!repairLoading && failed" src="../../../assets/images/chenggong.png" alt="" />
        <img v-if="!repairLoading && !failed" src="../../../assets/images/guanbi.png" alt="" />
        <div v-if="!repairLoading" style="margin-top: 10px">
          {{ repairResult }}
        </div>
      </div>
    </el-dialog>
    <el-image-viewer :zIndex="99999" v-if="showViewer" :on-close="() => {
        imgList = [];
        showViewer = false;
      }
      " :url-list="imgList" />
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import _api from "@/utils/request";

export default {
  name: "Table",
  components: { ElImageViewer },
  data() {
    return {
      auditShow: false,
      repairTitle: "",
      repairResult: "",
      repairLoading: false,
      loadingText: "",
      repairShow: false,
      showViewer: false,
      imgList: [],
      // 核账弹窗
      callAccountsProps: {
        auditNote: "",
        state: "2",
        command: "",
      },
      loading: false,
      seachDataList: [],
      auditStatus: 1,
      RemarksTitle: "",
      RemarksShow: false,
      masterNote: "",
      butLoading: false,
      voucherBtnLoading: false,
      rowId: "",
      rowOpt: "",
      btnType: "",
      command: "",
      wechatFollowNo: "",
      operation: {},
      applyNumber: {},
      voucherList: [],
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      pageNum: 1,
      failed: true,
      //弹窗
      dialogVisible: false, // 弹框的出现与否
      FollowNoShow: false,
      dialogTitle: "", // 标题
      FollowNoTitle: "",
      dialogWidth: "600px",
      dialongDecide: "",
      tableColumns: [
        { label: "出账单号", prop: "transferNo" },
        { slotName: "businessType" },
        { slotName: "merchantName" },
        { slotName: "arrivalMethod" },
        { slotName: "publicType" },
        { slotName: "chamberlain" },
        { slotName: "bankCardCode" },
        { slotName: "bankName" },
        { slotName: "bankOpening" },
        { slotName: "alipayAccount" },
        { slotName: "amount" },
        { slotName: "cashOutFee" },
        { slotName: "arrivalMoment" },
        { slotName: "merchantAddress" },
        { label: "创建时间", prop: "createTime" },
        { label: "更新时间", prop: "updateTime" },
        { slotName: "payNote" },
        { slotName: "voucher" },
        { slotName: "failnote" },
        { slotName: "note" },
        { slotName: "operation" },
      ],
    };
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  created() {
    this.handleCurrentChange();
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
  },
  methods: {
    auditTrunon(row) {
      this.callAccountsProps = { ...row, ...this.callAccountsProps };
      this.auditShow = true;
    },
    //审核确认
    auditksSure() {
      console.log(this.callAccountsProps);
      if (
        !this.callAccountsProps.auditNote &&
        this.callAccountsProps.state == "3"
      ) {
        return this.$message.error("请输入不通过原因！");
      }
      if (!this.callAccountsProps.command) {
        return this.$message.error("请输入动态口令！");
      }
      let outsParams = {
        auditNote: "",
        command: this.callAccountsProps.command,
        rejectCause: "",
        state: this.callAccountsProps.state,
        transferNo: this.callAccountsProps.transferNo,
      };
      //	2通过/3驳回
      if (this.callAccountsProps.state == "3") {
        outsParams.rejectCause = this.callAccountsProps.auditNote
        outsParams.auditNote = ''
      } else {
        outsParams.rejectCause = ""
        outsParams.auditNote = this.callAccountsProps.auditNote
      }
      this.butLoading = true;
      _api.outditTransfer(outsParams).then((res) => {
        this.auditShow = false;
        this.callAccountsProps = {
          auditNote: "",
          state: "2",
          command: "",
        }
        this.butLoading = false;
        this.handleCurrentChange();
      }).catch(err=>{
        this.butLoading = false;
      })
      ;
    },
    //审核关闭
    auditClosed() {
      this.callAccountsProps = {
        auditNote: "",
        state: "2",
        command: "",
      }
      this.auditShow = false;
    },
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val;
        this.page.pageNum = val;
      }
      const baseRequest = {
        alipayAccount: "",
        arrivalMethod: this.SeachParams.arrivalMethod,
        arrivalMoment: "",
        bankCardCode: this.SeachParams.bankCardCode,
        businessType: this.SeachParams.businessType,
        companyId: this.SeachParams.companyId,
        merchantId: this.SeachParams.merchantId,
        otherName: this.SeachParams.otherName,
        transferNo: this.SeachParams.transferNo,
        transferStatus: this.auditStatus,
        userName: this.SeachParams.userName,
        payAdminId: this.SeachParams.payAdminId,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true;
      _api.outtranfeList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.seachDataList = res.data.ipage.records;
          this.applyNumber.ingNum = res.data.ingNum;
          this.applyNumber.successNum = res.data.successNum;
          this.applyNumber.failNum = res.data.failNum;
          this.applyNumber.waitNum = res.data.waitNum;
          this.applyNumber.rejectNum = res.data.rejectNum;
          this.page.total = res.data.ipage.total;
          this.page.pageNum = res.data.ipage.current;
          this.loading = false;
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding == "function") {
          this.SeachParams.disuseLoding();
        }
      });
    },
    repairClosed() {
      this.repairShow = false;
    },
    // 弹出备注弹框
    handleBtn(row, type) {
      console.log(row);
      this.rowOpt = row.opt;
      this.transferNo = row.transferNo;
      if (type == "remark") {
        if (row.masterNote) {
          this.RemarksTitle = "修改备注";
        } else {
          this.RemarksTitle = "添加备注";
        }
        this.masterNote = row.note;
        this.RemarksShow = true;
      } else if (type == "supplement") {
        this.repairShow = true;
        this.failed = true;
        if (row.transferStatus == 2) {
          this.loadingText = "正在查询交易结果，请稍后";
          this.repairLoading = true;
          this.repairTitle = "补单查询-" + row.transferNo;
          _api
            .outTransferQuery({ transferNo: this.transferNo })
            .then((res) => {
              console.log(res);
              this.repairResult = res.msg || "补单查询成功";
              this.repairLoading = false;
            })
            .catch((err) => {
              console.log(err);
              this.repairResult = err.msg || "补单查询成功";
              this.repairLoading = false;
            });
        }
      }
    },
    // 关闭备注弹框
    remarksClosed() {
      this.RemarksShow = false;
      this.masterNote = "";
    },
    // 备注确定提交
    remarksSure() {
      this.butLoading = true;
      _api
        .outtranfeNote({ transferNo: this.transferNo, note: this.masterNote })
        .then((res) => {
          console.log(res);
          if (res.code === 1) {
            this.$message.success(res.msg);
            this.RemarksShow = false;
            this.handleCurrentChange();
          }
          this.butLoading = false;
        })
        .catch((err) => {
          this.butLoading = false;
        });
    },
    // 上账/下账 点击
    handBtn(type) {
      this.$router.push({ path: "/payment/setRemit", query: { type } });
    },
    btnClick(type) {
      this.auditStatus = type;
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
    // 查看凭证
    seeVoucher(row) {
      this.imgList = row;
      this.showViewer = true;
    },
    handleClose() {
      this.voucherList = [];
      this.adminName = "";
      this.command = "";
      this.dialogVisible = false;
    },
    // 添加微信流水单号
    setFollowNo(row, type) {
      this.rowId = row.id;
      this.wechatFollowNo = row.wechatFollowNo;
      if (type == "add") {
        this.FollowNoTitle = "添加微信流水号";
      } else {
        this.FollowNoTitle = "修改微信流水号";
      }
      this.FollowNoShow = true;
    },
    FollowNoClosed() {
      this.FollowNoShow = false;
    },
    FollowNoSure() {
      if (!this.wechatFollowNo) {
        this.$message.error("请输入微信流水单号");
        return;
      }
      this.butLoading = true;
      _api
        .wechatFollowNo({ id: this.rowId, wechatFollowNo: this.wechatFollowNo })
        .then((res) => {
          console.log(res);
          if (res.code === 1) {
            this.$message.success(res.msg);
            this.FollowNoShow = false;
            this.handleCurrentChange();
          }
          this.butLoading = false;
        })
        .catch((err) => {
          this.butLoading = false;
        });
    },
    // 上传图片后，将图片信息保存到对应参数中
    handleUploadGroupChange(list) {
      console.log(list);
      if (Array.isArray(list)) {
        this.voucherList = list;
      }
    },
    handleSure() {
      if (!this.voucherList.length) {
        this.$message.error("至少上传一张凭证。");
        return;
      } else if (!this.command) {
        this.$message.error("请输入动态口令");
        return;
      }
      this.voucherBtnLoading = true;
      let params = {
        accountRecordId: this.rowId,
        voucher: this.voucherList,
        command: this.command,
      };
      _api
        .voucherUpdate(params)
        .then((res) => {
          this.$message.success("操作成功");
          this.voucherBtnLoading = false;
          this.dialogVisible = false;
        })
        .catch(() => {
          this.voucherBtnLoading = false;
        });
    },
    // 导出
    clickExcel() {
      const params = {
        opt: this.auditStatus,
        contactsPhone: this.SeachParams.contactsPhone,
        merchantName: this.SeachParams.merchantName,
        note: this.SeachParams.note,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        approvedType: this.SeachParams.approvedType,
        isFindRepeat: this.SeachParams.isFindRepeat,
        isFindEmpty: this.SeachParams.isFindEmpty,
      };
      _api
        .accountExportExcel(params)
        .then((res) => {
          console.log(res);
          // if (this.SeachParams.startTime) {
          const content = res;
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            //
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                this.$message.error(data.msg || "导出失败");
              }
            } catch (err) {
              const fileName = "账务列表.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8"); // 设置读取的数据以及返回的数据类型为utf-8
        })
        .catch((err) => {
          console.log(err);
        });
    },
    wechatFollowNoInput(e) {
      this.wechatFollowNo = e.replace(/[\W]/g, "");
    },
    changeNumMoneyToChinese(money) {
      var cnNums = new Array(
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖"
      ); //汉字的数字
      var cnIntRadice = new Array("", "拾", "佰", "仟"); //基本单位
      var cnIntUnits = new Array("", "万", "亿", "兆"); //对应整数部分扩展单位
      var cnDecUnits = new Array("角", "分", "毫", "厘"); //对应小数部分单位
      var cnInteger = "整"; //整数金额时后面跟的字符
      var cnIntLast = "元"; //整型完以后的单位
      var maxNum = 999999999999999.9999; //最大处理的数字
      var IntegerNum; //金额整数部分
      var DecimalNum; //金额小数部分
      var ChineseStr = ""; //输出的中文金额字符串
      var parts; //分离金额后用的数组，预定义
      var Symbol = ""; //正负值标记
      if (money == "") {
        return "";
      }

      money = parseFloat(money);
      if (money >= maxNum) {
        alert("超出最大处理数字");
        return "";
      }
      if (money == 0) {
        ChineseStr = cnNums[0] + cnIntLast + cnInteger;
        return ChineseStr;
      }
      if (money < 0) {
        money = -money;
        Symbol = "负 ";
      }
      money = money.toString(); //转换为字符串
      if (money.indexOf(".") == -1) {
        IntegerNum = money;
        DecimalNum = "";
      } else {
        parts = money.split(".");
        IntegerNum = parts[0];
        DecimalNum = parts[1].substr(0, 4);
      }
      if (parseInt(IntegerNum, 10) > 0) {
        //获取整型部分转换
        var zeroCount = 0;
        var IntLen = IntegerNum.length;
        for (var i = 0; i < IntLen; i++) {
          var n = IntegerNum.substr(i, 1);
          var p = IntLen - i - 1;
          var q = p / 4;
          var m = p % 4;
          if (n == "0") {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              ChineseStr += cnNums[0];
            }
            zeroCount = 0; //归零
            ChineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m == 0 && zeroCount < 4) {
            ChineseStr += cnIntUnits[q];
          }
        }
        ChineseStr += cnIntLast;
        //整型部分处理完毕
      }
      if (DecimalNum != "") {
        //小数部分
        var decLen = DecimalNum.length;
        for (let i = 0; i < decLen; i++) {
          let n = DecimalNum.substr(i, 1);
          if (n != "0") {
            ChineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
      if (ChineseStr == "") {
        ChineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (DecimalNum == "") {
        ChineseStr += cnInteger;
      }
      ChineseStr = Symbol + ChineseStr;

      return ChineseStr;
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  .button_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .repair-conent {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .faultBtn {
    //width: 432px;
    height: 34px;
    background: #f9fbfd;
    border: 1px solid #c1d1ff;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;

    .active {
      text-align: center;
      color: white;
      padding: 0 20px;
      // width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      background: #0981ff;
      border-radius: 20px;
    }

    .none {
      text-align: center;
      color: #333;
      padding: 0 20px;
      // width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }

  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .operation-btn {
    /deep/.el-button {
      margin-left: 0;
    }
  }

  .remarks-tip {
    color: #0981ff;
    font-size: 16px;
    padding-left: 10px;
    border-left: 3px solid;
    margin-bottom: 10px;
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}

.call_accounts_info {
  margin: 10px 0;
}

.call_accounts_check {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 14px;
}

.call_accounts_result {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
</style>
