<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="80"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item
        class="el_from"
        label="商户类型:"
        slot="businessType"
        style="margin:0"
      >
        <el-select
          v-model="businessType"
          placeholder="请选择商户类型"
          filterable
          clearable
          @change="busilistChang"
        >
          <el-option
            v-for="item in businessList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="el_from"
        label="门店商家:"
        label-width="80px"
        slot="companyId"
        style="margin:0"
        v-if="businessType == '01'"
      >
        <el-select
          v-model="companyId"
          placeholder="请选择门店商家"
          filterable
          clearable
        >
          <el-option
            v-for="item in storeSelectList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        label="回收商家"
        label-width="80px"
        slot="merchantId"
        style="margin:0"
        v-if="businessType == '02'"
      >
        <el-select
          v-model="merchantId"
          clearable
          placeholder="请选择回收商家"
          filterable
        >
          <el-option
            v-for="item in merchantSelectList"
            :key="item.merchantId"
            :label="item.merchantName"
            :value="item.merchantId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="businessType == '03'"
        label="收款商户"
        slot="otherName"
        style="margin:0"
      >
        <el-input clearable placeholder="请输入收款商户" v-model="otherName">
        </el-input>
      </el-form-item>
      <el-form-item
        class="el_from"
        label="收款账户类型:"
        label-width="110px"
        slot="arrivalMethod"
        style="margin:0"
      >
        <el-select
          v-model="arrivalMethod"
          placeholder="请选择收款账户类型"
          filterable
          clearable
        >
          <el-option
            v-for="item in arrivalList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="el_from"
        label="打款人:"
        slot="approvedType"
        label-width="64px"
        style="margin:0"
      >
        <el-select v-model="payer" placeholder="请选择" filterable clearable>
          <el-option
            v-for="item in payerSelectList"
            :key="item.id"
            :label="item.userName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </GlobalForm>
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from 'moment'

export default {
  name: "Form",
  data() {
    return {
      payer: "",
      otherName: "",
      companyId: "",
      merchantId: "",
      businessType: "",
      arrivalMethod: "",
      merchantSelectList: [],
      storeSelectList: [],
      SeachParams: {},
      businessList: [
        {
          name: "门店商",
          id: "01",
        },
        {
          name: "回收商",
          id: "02",
        },
        {
          name: "其他",
          id: "03",
        },
      ],
      arrivalList: [
        {
          name: "支付宝",
          id: "01",
        },
        {
          name: "银行卡",
          id: "02",
        },
      ],
      payerSelectList: [],
      initData: {
        entryTime: [
          moment()
            .day(moment().day()-6)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
      },
      formItemList: [
        {
          key: "transferNo",
          type: "input",
          labelName: "出账单号:",
          placeholder: "请输入出账单号查询",
          lableWidth: "80px",
        },
        { slotName: "businessType" },
        { slotName: "companyId" },
        { slotName: "merchantId" },
        { slotName: "otherName" },
        { slotName: "arrivalMethod" },
        {
          key: "bankCardCode",
          type: "input",
          labelName: "收款卡号:",
          placeholder: "请输入完整收款卡号查询",
          lableWidth: "80px",
        },
        {
          key: "userName",
          type: "input",
          labelName: "收款人:",
          placeholder: "请输入收款人查询",
          maxlength: 20,
          lableWidth: "65px",
        },
        { slotName: "approvedType" },
        {
          key: "entryTime",
          type: "pickerOptions",
          labelName: "打款时间:",
          placeholder: "请选择打款时间",
          valueFormat: "timestamp",
          lableWidth: "80px",
          clearable: true,
        },
      ],
    };
  },
  created() {
    this.handleConfirm(this.initData);
    this.getadminSelectList();
  },
  methods: {
    busilistChang(e) {
      console.log(e);
      if (e == "01") {
        _api.getSelectList().then((res) => {
          if (res.code === 1) {
            this.storeSelectList = res.data;
          }
        });
      } else if (e == "02") {
        _api
          .listSimpleMerchantView({
            pageNum: 0,
            pageSize: 999,
          })
          .then((res) => {
            if (res.code === 1) {
              this.merchantSelectList = res.data;
            }
          });
      }
    },
    //打款人列表
    getadminSelectList() {
      _api.StaffManageList({ pageNum: 1, pageSize: 99999 }).then((res) => {
        this.payerSelectList = res.data.records;
      });
    },
    handleConfirm(data,cd) {
      console.log("提交了form", data);
      if (this.businessType == "01") {
        this.merchantId = "";
        this.otherName = "";
      } else if (this.businessType == "02") {
        this.companyId = "";
        this.otherName = "";
      } else {
        this.companyId = "";
        this.merchantId = "";
      }
      let SeachParams = {
        alipayAccount: "",
        arrivalMethod: this.arrivalMethod,
        arrivalMoment: "",
        bankCardCode:data&&data.bankCardCode? data.bankCardCode:"",
        businessType: this.businessType||"",
        companyId: this.companyId,
        merchantId: this.merchantId,
        otherName: this.otherName,
        transferNo: data&&data.transferNo?data.transferNo:"",
        transferStatus: null,
        userName: data&&data.userName?data.userName:"",
        payAdminId: this.payer,
        startTime: data&&data.entryTime ? moment(data.entryTime[0]).format("x") : "",
        endTime: data&&data.entryTime ? moment(data.entryTime[1]).format("x") : "",
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      };
      this.SeachParams = SeachParams;
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss"></style>
